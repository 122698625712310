<template>
  <div>

    <!-- Модальное окно - SafeNet Authentication Client -->
    <div class="modal is-active" v-if="modalActive">
      <div class="modal-background" v-on:click="openModal"></div>
      <div class="modal-card">
        <header class="modal-card-head"><p class="modal-card-title">{{ $t('Software installation') }}</p>
          <figure class="image is-48x48">
            <img src="@/assets/icons/computer.svg" alt="Image">
          </figure>
        </header>
        <section class="modal-card-body">
          <p v-html="$t('safenetModalText')"></p>
        </section>
        <footer class="modal-card-foot">
          <a class="button is-primary" v-on:click="openModal">{{ $t('Close') }}</a>
        </footer>
      </div>
    </div>
  <!--  <div>Gello</div>-->
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-3 has-background-light">
            <aside class="menu">
              <p class="menu-label">{{ $t('Download') }}</p>
              <ul class="menu-list is-size-7">
                <li><a v-bind:href="'/application/distr/SetupCryptoSocket_' + lang + '.zip?v=1.0.11.1224.2'">{{ $t('”CryptoSocket” sft') }}</a></li>
<!--                <li><a href="/application/distr/InstallCA_rus.exe">{{ $t('PKI Root Certificate Installation Wizard') }}</a></li>-->
                <li><a href="https://www.aladdin-rd.ru/support/downloads/jacarta_client_30/" target="_blank">{{ $t('”JaCarta Unified Client” software') }}</a></li>
                <li><a v-on:click="openModal">{{ $t('“SafeNet Authentication Client” software') }}</a></li>
                <li><a href="https://kbp.aladdin-rd.ru//index.php?View=entry&EntryID=84" target="_blank">{{ $t('JaCartaGOST device unlock utility') }}</a></li>
              </ul>
            </aside>
          </div>

          <div class="column is-6">
            <div class="columns">
              <div class="column">
                <h5>{{ $t('”CryptoSocket” software') }}</h5>
                <div style="height: 0.5em;"></div>
                <figure class="image is-96x96"><img src="@/assets/icons/cryptosocket.svg" style="height: 70px"></figure>
                <p><a v-bind:href="'/application/distr/SetupCryptoSocket_' + lang + '.zip?v=1.0.11.1224.2'">{{ $t('”CryptoSocket” software') }}</a></p>
              </div>
            </div>

<!--            <div class="columns">-->
<!--              <div class="column">-->
<!--                <h5>{{ $t('Certificates Software') }}</h5>-->
<!--                <div style="height: 0.5em;"></div>-->
<!--                <figure class="image is-96x96"><img src="@/assets/icons/cert.svg" style="height: 77px"></figure>-->
<!--                <a href="/application/distr/InstallCA_rus.exe">{{ $t('PKI Root Certificate Installation Wizard') }}</a>-->
<!--              </div>-->
<!--            </div>-->
            <div style="height: 1em;"></div>
            <div class="columns">
              <div class="column">
                <h5>{{ $t('Security token Software') }}</h5>
              </div>
            </div>
            <div class="columns has-text-left">
              <div class="column">
                <figure class="image is-96x96 is-center"><img src="@/assets/icons/usb.svg" style="height: 77px"></figure>
                <a href="https://www.aladdin-rd.ru/support/downloads/jacarta_client_30/" target="_blank">{{ $t('JaCarta Unified Client') }}</a>
              </div>
              <div class="column">
                <figure class="image is-96x96 is-center"><img src="@/assets/icons/usb.svg" style="height: 77px"></figure>
                <a class="modal-button" v-on:click="openModal">{{ $t('SafeNet Authentication Client version') }}</a>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <h5>{{ $t('Utilities') }}</h5>
                <div style="height: 0.5em;"></div>
                <figure class="image is-96x96"><img src="@/assets/icons/computer.svg" style="height: 77px"></figure>
                <a href="https://kbp.aladdin-rd.ru//index.php?View=entry&EntryID=84" target="_blank">{{ $t('JaCartaGOST device unlock utility') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'Software',
  data () {
    return {
      modalActive: false
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    }
  },
  methods: {
    openModal () {
      this.modalActive = !this.modalActive
    }
  }
}
</script>

<style scoped lang="scss">
  .is-size-7 {
    font-size: 0.81rem !important;
  }
  a {
     text-decoration: underline;
  }
  .menu-label {
    font-size: 0.81em;
    letter-spacing: 0;
    color: #363636;
  }
  h5 {
    font-size: 1.1em;
    margin-bottom: 0.8em;
    font-weight: 600;
    line-height: 1.1;
  }
  /*.is-center {*/
  /*  margin: auto;*/
  /*}*/
</style>
